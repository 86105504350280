@use '../../config/' as *;

.waf-for-athletes {
    @extend %common-input-swiper-btn;
    @extend %accordion-common;
    @extend %tab-section-common;
    .tab-section {
        padding-inline: 0;
        .table {
        &-header .title {
            flex-grow: 1;
            @extend %text-center;
        }
        &-row {
            width: 56.6rem;
        }
        &-data {
            @extend %px-4;
        }
        .text {
            font-size: 1.2rem;
        }
        }
        .start {
        justify-content: center;
        }
        .start,
        .yob {
        flex-basis: 8.4rem;
        }
        .fname,
        .lname {
        flex-basis: 14.4rem;
        }
        .nation {
        flex-basis: 11rem;
        }
    }
    .waf-fixtures {
        padding-block: 0;
        margin-bottom: 0;
        .layout-wrapper {
          max-width: unset;
        }
        .waf-head, .filter-options, .tabs {
          display: none;
        }
        .form-wrapper {
          margin-top: calc(25*var(--space-1));
        }
    }
    .card-section .card-content:not(:last-child) {
        margin-bottom: 0;
    }
    .services {
        @extend %event-card-small;
    }
    .training-tips {
        @extend %event-card-big;
    }
    .value {
        margin-bottom: var(--space-8);
    }
}
@include mq(col-tablet) {
    .waf-for-athletes {
        .card-body {
          padding-block: 0;
        }
        .tab-section {
          .table .text {
            font-size: 1.4rem;
          }
          .table-row {
            width: 100%;
            min-width: 56.6rem;
          }
          .start, .yob {
            flex-basis: 11.9%;
            min-width: 8.4rem;
          }
          .fname, .lname {
            flex-basis: 28.6%;
            min-width: 14.4rem;
          }
          .nation {
            flex-basis: 19%;
            min-width: 11rem;
          }
        }
        .value {
          margin-bottom: var(--space-14);
        }
        .services .card-list, .training-tips .card-list {
          @include card-count(2, var(--space-4), wrap);
        }
        .training-tips .card-list {
          justify-content: flex-end;
        }
        .waf-fixtures {
          .form-wrapper {
            margin-top: calc(12*var(--space-1));
          }
        }
    }
}
@include mq(col-desktop) {
    .waf-for-athletes .services .card-list {
        @include card-count(3, var(--space-6), wrap);
    }
}
