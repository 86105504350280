@use '../../config/' as *;
.waf-about {
  padding-block: var(--space-8);
  margin-inline: var(--space-2-neg);
  margin-bottom: var(--space-8);
  contain: paint;
  @extend %relative;
  &::before {
    content: "";
    aspect-ratio: .58;
    @include background(null, 'cssimages/pattern/blue-gradient-mobile.png', center / contain no-repeat);
    pointer-events: none;
    @extend %w-100;
    @extend %position-t-l;
    @extend %zindex-pattern;
  }
  .card {
    &-label {
      margin-block: 0 var(--space-8);
      @extend %title-40;
    }
    &-body {
      @extend %flex-column;
      @extend %gap-6;
    }
  }
  .text {
    font-weight: 700;
    @extend %neutral-70;
  }
}
@include mq(col-tablet) {
  .waf-about {
    padding-block: var(--space-10);
    margin-bottom: var(--space-20);
    contain: unset;
    &::before {
      aspect-ratio: 1.5;
      top: unset;
      bottom: -46.1rem;
      scale: -1 1;
      @include background(null, 'cssimages/pattern/blue-gradient-left.png', center left / cover no-repeat);
    }
    .card {
      &-item {
        display: grid;
        gap: var(--space-6);
        grid-template-columns: 40% auto;
      }
      &-label {
        font-size: 3.2rem;
      }
    }
    .text {
      font-size: 2rem;
      line-height: 1.4;
    }
  }
}