@use '../../config/' as *;
.waf-faq {
  @extend %accordion-common;
  .btn.btn-icon-ar {
    width: max-content;
    @extend %mt-10;
  }
}
.blue-gradient {
  .waf-faq {
    @include bgImg("cssimages/pattern/blue-gradient-top.png", null, 100% 100%);
  }
}
.waf-arena-faq.waf-faq {
  margin-bottom: var(--space-8);
  .waf-body {
    margin-top: 0;
  }
  .card-section {
    padding: var(--space-8) 0;
  }
}
.wtc-event-page {
  .waf-row-faq-eventstrip {
    position: relative;
    isolation: isolate;
    &::after {
      content: '';
      height: calc(100% + 127.5rem);
      position: absolute;
      top: 0;
      @include bgImg("cssimages/pattern/3star-blue-gradient.webp", null, cover);
      @extend %zindex-pattern;
      @extend %w-100;
    }
  }
}
@include mq(col-tablet) {
  .waf-arena-faq.waf-faq {
    .waf-body {
      margin-bottom: var(--space-20);
    }
    .card {
      &-section {
        padding-block: var(--space-20);
      }
      &-label {
        font-size: 3.2rem;
      }
    }
  }
  .wtc-event-page {
    .waf-row-faq-eventstrip {
      &::after {
        height: calc(100% + 94.5rem);
      }
      .card-section {
        padding-inline: 0;
      }
    }
  }
}
@include mq(col-xl) {
  .wtc-event-page {
    .waf-row-faq-eventstrip {
      &::after {
        height: calc(100% + 114.5rem);
      }
    }
  }
}