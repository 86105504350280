@use '../../config/' as *;
.waf-contact {
  padding-block: 0 var(--space-14);
  margin-bottom: 0;
  @extend %page-head-blue-bg;
  @extend %card-label-content;
  .waf-head .title {
    @extend %mb-0;
  }
  .btn-icon-ar {
    width: fit-content;
    padding-inline: var(--space-5);
    @extend %mt-4;
  }
}
.waf-fourstar-contact {
  .waf-head {
    @extend %d-none;
  }
  .layout-wrapper {
    > .waf-head {
      margin-top: unset;
    }
    > .waf-body::before {
      content: unset;
    }
  }
  .waf-body {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.waf-component {
  &.waf-fourstar-contact {
    @extend %p-y-12-20;
  }
}
.wtc-event-page {
  .waf-contact {
    .waf-body {
      margin-top: 8rem;
      &:has(.card-section) {
        background-color: unset;
      }
    }
  }
}
@include mq(col-tablet) {
  .waf-contact {
    padding-bottom: 9.6rem;
    .waf-body {
      margin-top: -8rem;
    }
  }
  .waf-fourstar-contact {
    .layout-wrapper > .waf-body {
      margin-top: unset;
    }
  }
  .waf-component {
    &.waf-fourstar-contact {
      padding-block: var(--space-20);
    }
  }
}