@use '../../config/' as *;

.waf-registration {
  @extend %accordion-common;
  .card-head .card-action {
    @extend %d-none;
  }
  .label {
    @extend %title-50;
  }
  .value {
    font-size: 1.6rem;
  }
  .waf-body {
    @extend %bg-neutral-5;
  }
  .accordion-header .btn-text {
    font-size: 1.6rem;
  }
  .card-content {
    &:first-child {
      .card-action {
        display: none;
      }
    }
  }
}
@include mq(col-tablet) {
    .waf-registration {
        .label {
          margin-bottom: var(--space-4);
          font-size: 2.4rem;
          line-height: 1.4;
        }
        .value {
          margin-bottom: var(--space-2);
        }
        .card {
          &-section {
            &:first-child {
              .card-head {
                width: 38%;
              }
              .card-body {
                width: calc(62% - var(--space-6));
              }
              .label {
                padding-bottom: var(--space-4);
              }
            }
            &:nth-child(2) {
              .card-info {
                display: flex;
                gap: var(--space-6);
              }
              .card-content {
                width: 30rem;
                &:nth-child(2) {
                  width: calc(100% - 30rem - var(--space-6));
                  flex-grow: 1;
                }
              }
            }
          }
          &-head {
            .card-action {
              margin-top: var(--space-8);
              display: block;
            }
          }
          &-body {
            .card-action {
              display: none;
            }
          }
          &-content {
            &:first-child {
              .card-action {
                display: block;
                margin-top: var(--space-8);
              }
            }
          }
          &-label {
            margin-bottom: var(--space-8);
            font-size: 3.2rem;
          }
        }
    }
}