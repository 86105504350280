@use '../../config/' as *;

.waf-documents {
    padding-block: 0 var(--space-14);
    margin-bottom: 0;
    @extend %page-head-blue-bg;
    @extend %accordion-common;
    .layout-wrapper{
        max-width: unset;
    }
    .head-wrap{
        margin-block: 21.6rem;
    }
    .waf-body{
        margin-top: -13.6rem;
    }
    .card{
        &-section{
            @extend %mb-24;
            > .card-item:not(:last-child){
                @extend %mb-20;
            }
        }
        &-list{
            @extend %event-card-small;
            .title{
                font-size: 1.4rem;
                -webkit-line-clamp: 4;
            }
            .card-content{
                max-width: 86%;
            }
            .card-content:not(:last-child){
                @extend %mb-0;
            }
            .btn-link{
                &::before{
                    content: '\e84d';
                }
            }
        }
        &-info{
            > .card-content:not(:last-child){
                @extend %mb-6;
            }
        }
    }
    .option-list{
        @extend %my-6;
    }
    .label{
        margin-bottom: var(--space-2);
    }
    .value+.label{
        @extend %mt-6;
    }
}

@include mq(col-tablet){
    .waf-documents{
        .card{
            &-list{
                @include card-count(2, var(--space-6), wrap);
            }
        }
    }
}

@include mq(col-lg){
    .waf-documents{
        .card{
            &-list{
                > *{
                    width: calc(100% / 3 - ((2) * var(--space-6) / 3));
                }
            }
        }
    }
}