@use '../../config/' as *;

.waf-shop {
  padding: var(--space-10) 0;
  margin-inline: var(--space-2-neg);
  margin-bottom: 0;
  @extend %bg-neutral-5;
  .layout-wrapper > *:not(:last-child) {
    @extend %mb-10;
  }
  .shop-section {
    @extend %py-10;
  }
  .title {
    letter-spacing: -.05rem;
    @extend %mb-10;
    @extend %title-40;
  }
  .banner {
    &-content {
      .image {
        aspect-ratio: 2.68;
        object-fit: cover;
        object-position: center;
        @extend %border-radius-m;
      }
    }
  }
  .card {
    &-list {
      overflow-x: auto;
      @extend %flex-n-n;
      @extend %gap-6;
    }
    &-item {
      flex-basis: calc(100% - var(--space-14));
      flex-shrink: 0;
      aspect-ratio: 1;
      @extend %relative;
    }
    &-content {
      padding: var(--space-18) var(--space-6) var(--space-6) var(--space-6);
      align-content: flex-end;
      flex-wrap: wrap;
      gap: var(--space-2) var(--space-6);
      @include position-combo(inset);
      @extend %flex;
      @extend %neutral-5;
    }
    &-thumb {
      @extend %h-100;
    }
    &-img {
      object-fit: cover;
      @extend %h-100;
      @extend %border-radius-m;
    }
    &-label {
      flex-basis: 100%;
      @extend %text-l;
    }
    &-title {
      flex-basis: calc(100% - var(--space-12));
      @extend %mb-0;
      @extend %title-40;
    }
    &-action {
      width: 2.4rem;
      @extend %flex;
      @extend %neutral-5;
      &::before {
        @include icon(arrow-right, 24);
      }
      &::after {
        content: "";
        @include position-combo(inset);
        @extend %border-radius-m;
      }
      .text {
        @extend %font-0;
      }
    }
    &-content {
      @extend %d-none;
    }
  }
}
@include mq(col-tablet) {
    .waf-shop {
        padding-block: var(--space-20);
        .layout-wrapper > *:not(:last-child) {
        margin-bottom: 9.6rem;
        }
        .shop-section {
        padding-block: 0;
        }
        .banner-content .image {
        height: 23rem;
        aspect-ratio: unset;
        }
        .title {
        margin-bottom: var(--space-14);
        font-size: 3.2rem;
        line-height: 1.3;
        letter-spacing: -.064rem;
        }
        .shop-activity {
        .card {
            &-list {
            justify-content: flex-end;
            overflow-x: unset;
            }
            &-item {
            max-width: 30rem;
            position: relative;
            flex: 1;
            &::before {
                content: "";
                border-radius: var(--border-radius-m);
                background: linear-gradient(180deg, hsl(var(--hsl-pure-black-0) / 0) 0%, hsl(var(--hsl-pure-black-0) / .5) 100%);
                pointer-events: none;
                @include position-combo(inset);
            }
            .card-img {
                object-position: left center;
            }
            }
        }
        }
        .shop-category {
        padding: calc(var(--container-white-space) / 2) calc(var(--container-white-space) / 2) 0;
        position: relative;
        gap: var(--space-6);
        isolation: isolate;
        @include flex-config(flex, null, space-between, null);
        &::before {
            content: "";
            aspect-ratio: 3.93;
            border-radius: var(--border-radius-m);
            z-index: map-get($map: $zindex, $key: pattern);
            background: url(/static-assets/images/cssimages/pattern/fourstar-results-d.png?v=#{$image-version}) center / cover no-repeat;
            pointer-events: none;
            @include position-combo(inset);
        }
        .banner-content {
            max-width: 37rem;
            width: 31%;
            display: block;
        }
        }
        .card {
        &-logo {
            max-width: 22rem;
            width: 60%;
            margin-bottom: var(--space-8);
        }
        &-info {
            color: var(--neutral-5);
            font: 700 1.6rem/1.4 $font-primary;
            letter-spacing: -.05rem;
        }
        }
        .shop-category {
        .card {
            &-list {
            width: calc(69% - var(--space-6));
            justify-content: flex-end;
            }
            &-item {
            max-width: 37rem;
            flex: 1;
            }
        }
        }
    }
}
@include mq(col-lg) {
    .waf-shop .card-info {
      font-size: 2.4rem;
      @include truncate-text(2);
    }
}